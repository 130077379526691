<template>
  <div id="print_invoice_pdf"
       style="background-color: white;font-size: 18px;font-weight: 700;color: black;">
    <div v-for="n in data.count">
      <qr-code
        :size="data.size"
        style="margin: 10px auto;width: 255px;"
        :text="data.product_code"></qr-code>
    </div>
  </div>

</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
// import instance from '../../../axios.service'
import Swal from "sweetalert2";
import {email, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import instance_order from "@/services/order";

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    data : {
      size: 40,
      count: 10,
      product_code: ''
    }
  }),
  validations() {
    return {
      editedItem: {
        name: {required},
        value: {required},
      }
    }
  },
  computed: {},
  watch: {},

  created() {

  },
  mounted() {

    this.data.product_code = this.$route.query.product_code;
    this.data.size = parseFloat(this.$route.query.size);
    this.data.count = parseInt(this.$route.query.count);
    setTimeout(function () {
      window.print();
    },150);
  },
  methods: {
    imgError() {

    }
  }
}
</script>

<style>

@page {
  size: A4;
  margin: 0;
}

.container {
  padding: 100px;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
}
</style>
